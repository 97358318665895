declare var angular: angular.IAngularStatic;

export class DynamicSpots {
    // Bindable properties
    public SearchParams: any;
    public spotThumbs: any;
    public activeUploads: any;
    public PAGE_SIZE: number;
    public loadedPages: any;
    public numItems: any;
    public searching: Boolean = false;
    
    constructor(
        public params: any,
        public SpotResourceFactory: any,
        public AssetResourceFactory: any,
        public StatusService: any,
        public loginService: any,
        public $timeout: any,
        public $scope: any,
        public accountType: any
    ) {
        /* BINDABLE: DATA */
        this.SearchParams = params;
        this.spotThumbs = [];
        this.activeUploads;
        this.PAGE_SIZE = 50;

        $scope.$on('$stateChangeSuccess', (event:any, toState:any, toParams:any) => {
            this.SearchParams = toParams;
            Object.keys(this.SearchParams).forEach(key => (this.SearchParams[key] === undefined || this.SearchParams[key] === '' || this.SearchParams[key] === null) ? delete this.SearchParams[key] : {});
        });

        /* Get things rolling */
        this.reloadPages_();
    }

    getItemAtIndex(index:any, lazy:any) {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
        var page = this.loadedPages[pageNumber];
        lazy = lazy === undefined ? false : lazy;

        if (page) {
            return page[index % this.PAGE_SIZE];
        } else if (page !== null) {
            if (!lazy) {
                this.fetchPage_(pageNumber);
            }
        }
    };

    getLength() {
        return this.numItems;
    };

    setItemAtIndex(index:any, item:any) {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
        var page = this.loadedPages[pageNumber];

        // Only update the screen if the spot is currently loaded
        if (page) {
            page[index % this.PAGE_SIZE] = item;
        }
    };

    fetchPage_(pageNumber:any) {
        var self = this;
        this.searching = true;

        // Set the page to null so we know it is already being fetched.
        self.loadedPages[pageNumber] = null;

        var pageOffset = pageNumber * self.PAGE_SIZE;

        var sortField = this.SearchParams.sortField ? this.SearchParams.sortField : 'date_created';
        var sortDirection = this.SearchParams.sortDirection
            ? this.SearchParams.sortDirection
            : 'DESC';

        this.$timeout(() => {
            if (self.accountType.replace(/_/g, '') === 'QCVENDOR') {
                self.SearchParams.SpotsStatus = 'PENDING_QC';
            }

            // Filter out blank values, since we don't want to search by those
            for (var prop in self.SearchParams) {
                if (self.SearchParams.hasOwnProperty(prop)) {
                    self.SearchParams[prop] =
                        self.SearchParams[prop] === '' || self.SearchParams[prop] === null
                            ? undefined
                            : self.SearchParams[prop];
                }
            }

            self.SearchParams.offset = pageOffset;
            self.SearchParams.limit = self.PAGE_SIZE;
            self.SearchParams.sortField = sortField;
            self.SearchParams.sortDirection = sortDirection;

            // COM-11612 - this is a hack to get around the fact that title is often entered with a colon in it.
            // The colon messes up the Node parsing of the field, so pass this one like admin does
            var tempParams = angular.copy(self.SearchParams);

            for(const [key, value] of Object.entries(tempParams)) {
                if (value === undefined || value === null || value === '') {
                    delete tempParams[key];
                }
            }

            //COM-12102 - Have to copy/format the ISCIs properly in order to search for/create the right ones,
            // as well as retain the current search criteria
            if (self.SearchParams.isci) {
                tempParams.isci = self._formatIsci(tempParams.isci);
                if (tempParams.isci instanceof Array && tempParams.isci.length > 1) {
                    tempParams.isci = tempParams.isci.join('\n');
                } else {
                    tempParams.isci = tempParams.isci[0];
                }
            }

            if (self.SearchParams.title) {
                tempParams.title = 'like:' + self.SearchParams.title;
            }

            if (self.SearchParams.notes) {
                tempParams.notes = 'like:' + self.SearchParams.notes;
            }

            if (!self.SearchParams.SpotsStatus && self.accountType === 'PRODUCTIONSERVICESVENDOR') {
                tempParams.SpotsStatus = [
                    'PENDING_PROD_SVCS',
                    'PROCESSING',
                    'REJECTED',
                    'UPLOADED',
                    'UPLOADING',
                    'VERIFYING',
                ];
            }

            if (self.SearchParams.SpotsStatus) {
                if (self.SearchParams.SpotsStatus !== 'RESLATE') {
                    tempParams.SpotsStatus = self.SearchParams.SpotsStatus;
                    delete tempParams.reslateFlag;
                } else {
                    delete tempParams.SpotsStatus;
                    tempParams.reslateFlag = true;
                }
            }

            self.SpotResourceFactory.getProviderSpots(
                tempParams,
                {},
            ).subscribe(
                (spots:any) => {
                    spots = spots;
                    // Preset all of the statuses with each new page requested (so we don't end up doing them all at the same time
                    for (let i = 0; i < spots.rows.length; i++) {
                        spots.rows[i].statusBar = self.readStatus(spots.rows[i]);
                    }
                    self.loadedPages[pageNumber] = spots.rows;
                    self.numItems = spots.count;
                    self.loadedPages[pageNumber] = self.loadedPages[pageNumber].map(function (
                        data:any
                    ) {
                        if (
                            data.ThumbnailAsset &&
                            data.ThumbnailAsset.AssetContent &&
                            data.ThumbnailAsset.AssetContent.contentUuid
                        ) {
                            self._getThumbnail(
                                data.isci,
                                data.ThumbnailAsset.AssetContent.contentUuid
                            );
                        }
                        return data;
                    });
                    this.searching = false;
                },
                (err:any) => {
                    console.log(err);
                    self.loadedPages[pageNumber] = [];
                }
            );
        });
    };

    reloadPages_() {
        /**
         * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
         */
        this.loadedPages = {};

        /** @type {number} Total number of items. */
        this.numItems = 0;

        /** @const {number} Number of items to fetch per request. */
        this.PAGE_SIZE = 50;

        this.getItemAtIndex(0, false);
    };

    _getThumbnail(isci:any, uuid:any) {
        let self = this;
        self.AssetResourceFactory.getImageAsset(
            { uuid },
            {},
            function success(asset:any) {
                var blob = new Blob([asset.data], {
                    type: asset.config['Content-Type'],
                });
                self.spotThumbs[isci] = URL.createObjectURL(blob);
            },
            function failure() {
                // Unable to retrieve the image asset for the thumbnail
            }
        );
    };

    getSpotThumb(isci:any) {
        return this.spotThumbs[isci];
    };

    readStatus(spot:any) {
        if (spot === undefined) {
            return;
        }

        var session = this.loginService.getSessionData();
        this.activeUploads = session.activeUploads ? session.activeUploads : [];
        for (let i = 0; i < this.activeUploads.length; i++) {
            if (this.activeUploads[i].spotId === spot.id) {
                spot.status = 'UPLOADING';
            }
        }

        return this.StatusService.readSpotStatus(spot);
    };

    _formatIsci(isci:any) {
        var parsedIsci = isci.trim().split(/\n+|,+|;+/);
        if (parsedIsci instanceof Array && parsedIsci.length > 1) {
            var validIsciArray = [];
            //remove any that ended up empty due to extra characters, and trim
            for (let i = 0; i < parsedIsci.length; i++) {
                if (parsedIsci[i].trim().length > 0) {
                    validIsciArray.push(parsedIsci[i].trim().replace(/\W/g, '').toUpperCase());
                }
            }
            return validIsciArray;
        } else {
            return [isci.replace(/\W/g, '')];
        }
    }
}
