/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as moment from 'moment';

import { UpdatedDatePanelController } from "../orders/updatedDatePanel-controller";

//// CONTROLLER ////
export class SpotSearchController {
    // Private values
    private _sessionData: any;
    private newAgency: Boolean = false;
    private newAdvertiser: Boolean = false;
    private newBrand: Boolean = false;

    // Bindable
    public searchParams: any;
    public formats: any;
    public statuses: any;
    public createdDateSearchText: String;
    public updatedDateSearchText: String;
    public validDateSearchText: String;
    public minDate: any;
    public isFranchise: Boolean;
    public minDescriptionLength: Number;
    public maxDescriptionLength: Number;
    public accountType: String;
    public filteredDuration: any;
    public searchAgencies: String = '';
    public searchAdvertiser: String = '';
    public searchBrand: String = '';

    static get $inject() {
        return [
            '$state',
            '$stateParams',
            '$scope',
            'loginService',
            'AdvertiserResourceFactory',
            'AgencyResourceFactory',
            'BrandsResourceFactory',
            'EnumService',
            '$mdPanel',
            '$timeout',
            '$filter',
        ];
    }

    constructor(
        public $state: any,
        public $stateParams: any,
        public $scope: any,
        public loginService: any,
        public AdvertiserResourceFactory: any,
        public AgencyResourceFactory: any,
        public BrandsResourceFactory: any,
        public EnumService: any,
        public $mdPanel: any,
        public $timeout: any,
        public $filter: any
    ) {
        /* BINDABLE: INJECTS */
        Object.assign(this, {
            // $timeout,
            $state,
            $stateParams,
            $scope,
            loginService,
            AdvertiserResourceFactory,
            AgencyResourceFactory,
            BrandsResourceFactory,
            EnumService,
            $mdPanel,
            $timeout,
            $filter,
        });

        this._sessionData = loginService.getSessionData();

        /* BINDABLE : DATA */
        this.searchParams = {};
        this.formats = [];
        this.statuses = [];
        this.createdDateSearchText = '';
        this.updatedDateSearchText = '';
        this.validDateSearchText = '';
        this.minDate = new Date();
        this.isFranchise = this._sessionData.franchiseCustomer && !loginService.isAdmin();
        this.minDescriptionLength = 3;
        this.maxDescriptionLength = 100;
        this.accountType = loginService.getSessionData().accountType.replace(/_/g, '');
    }

    $onInit() {
        let vm = this;

        vm.searchParams = vm.$stateParams;
        vm.formatDuration();

        if (vm.accountType === 'QCVENDOR') {
            vm.searchParams.SpotsStatus = 'PENDING_QC';
        }

        vm.EnumService.getEnum('Spot', 'format').then(
            function (formats: any) {
                vm.formats = formats;
            },
            function () {
                vm.formats = [];
            }
        );

        if (vm.accountType === 'PRODUCTIONSERVICESVENDOR') {
            vm.statuses = [
                {
                    value: 'PENDING_PROD_SVCS',
                    label: 'Pending Prod Svcs',
                },
                {
                    value: 'PROCESSING',
                    label: 'Processing',
                },
                {
                    value: 'REJECTED',
                    label: 'Rejected',
                },
                {
                    value: 'UPLOADED',
                    label: 'Uploaded',
                },
                {
                    value: 'UPLOADING',
                    label: 'Uploading',
                },
                {
                    value: 'VERIFYING',
                    label: 'Verifying',
                },
            ];
        } else {
            vm.EnumService.getEnum('Spot', 'status').then(
                function (statuses: any) {
                    vm.statuses = statuses;
                    // Need to handle reslate spots manually since this is not an actual 'status', but a flag
                    vm.statuses.push({
                        value: 'RESLATE',
                        label: 'Reslate Needed',
                    });
                },
                function () {
                    vm.statuses = [];
                }
            );
        }
        vm.searchParams.advertiserId = vm.searchParams.advertiserId
            ? vm.searchParams.advertiserId
            : '';
        vm.searchParams.agencyId = vm.searchParams.agencyId
            ? vm.searchParams.agencyId
            : '';
        vm.searchParams.brandId = vm.searchParams.brandId
            ? vm.searchParams.brandId
            : '';
        vm.searchParams.format = vm.searchParams.format ? vm.searchParams.format : '';
        vm.searchParams.status = vm.searchParams.status ? vm.searchParams.status : '';
        vm.searchParams.includeArchives = vm.searchParams.includeArchives === 'true';

        if (!vm._sessionData.ignoreDateRestriction && !vm.searchParams.clearedFromDate) {
            vm.minDate = new Date(
                vm.minDate.getFullYear(),
                vm.minDate.getMonth() - 18,
                vm.minDate.getDate()
            );
            if (!vm.searchParams.updatedDateFrom) {
                vm.searchParams.updatedDateFrom = vm.minDate;
            }
        } else {
            vm.minDate = null;
        }

        vm._setCreatedDateSearchText();
        vm._setUpdatedDateSearchText();
        vm._setValidDateSearchText();
        if (vm.searchParams.agencyId) {
            vm._setAgencySearchText(vm.searchParams.agencyId);
        }

        if (vm.searchParams.advertiserId) {
            vm._setAdvertiserSearchText(vm.searchParams.advertiserId);
        }

        if (vm.searchParams.brandId) {
            vm._setBrandSearchText(vm.searchParams.brandId);
        }
    }

    checkUpdatedDate() {
        let vm = this;

        if (!vm.updatedDateSearchText) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.updatedDateTo = null;
            vm.searchParams.dateUpdated = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateTo) {
            vm.searchParams.updatedDateTo = null;
        } else {
            vm.searchParams.clearedFromDate = null;
        }
    }

    checkCreatedDate() {
        let vm = this;

        if (!vm.createdDateSearchText) {
            vm.searchParams.createdDateFrom = null;
            vm.searchParams.createdDateTo = null;
        }
    }

    checkValidDate() {
        let vm = this;

        if (!vm.validDateSearchText) {
            vm.searchParams.validFrom = null;
            vm.searchParams.validTo = null;
        }
    }

    formatDuration() {
        let vm = this;

        vm.filteredDuration = vm.$filter('timeDuration')(vm.searchParams.duration);
    }

    search() {
        let vm = this;

        if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.clearedFromDate = true;
        } else {
            vm.searchParams.clearedFromDate = null;
        }

        vm.checkUpdatedDate();
        vm.checkCreatedDate();

        // convert dates
        vm.convertDuration();
        vm.$state.go('spotList', vm.searchParams, {
            notify: true,
            inherit: false,
            reload: true,
        });
    }

    clear() {
        let vm = this;

        vm.searchParams = {};
        vm.createdDateSearchText = '';
        if (vm._sessionData.ignoreDateRestriction) {
            vm.updatedDateSearchText = '';
        } else {
            vm.minDate = new Date();
            vm.minDate = new Date(
                vm.minDate.getFullYear(),
                vm.minDate.getMonth() - 18,
                vm.minDate.getDate()
            );
            vm.searchParams.updatedDateFrom = vm.minDate;
            vm._setUpdatedDateSearchText();
        }
        vm.validDateSearchText = '';
        vm.searchAgencies = '';
        vm.searchAdvertiser = '';
        vm.searchBrand = '';
    }

    loadAdvertisers(searchTerm: String) {
        let vm = this;

        return new Promise((resolve, reject) => {

            // This code modification helps prevent the hirachy search blockage for Advertisers COM-18838
            let agencyIdentificat = vm.searchParams.agencyId ? vm.searchParams.agencyId : null;
            if (agencyIdentificat === null) {
                vm.AdvertiserResourceFactory.getAll(
                    {
                        name: searchTerm,
                        providerAccountId: vm._sessionData.accountId,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (advertisers: any) => {
                        resolve(advertisers);
                    },
                    () => {
                        resolve([]);
                    }
                );

            } else {
                vm.AdvertiserResourceFactory.getAll(
                    {
                        agencyId: agencyIdentificat,
                        name: searchTerm,
                        providerAccountId: vm._sessionData.accountId,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (advertisers: any) => {
                        resolve(advertisers);
                    },
                    () => {
                        resolve([]);
                    }
                );
            }


        })
    }

    loadAgencies(searchTerm: String) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.AgencyResourceFactory.getAll(
                {
                    name: searchTerm,
                    providerAccountId: vm._sessionData.accountId,
                    activeOnly: true,
                    limit: 15,
                }
            ).subscribe(
                function success(agencies: any) {
                    resolve(agencies.rows);
                },
                function failure() {
                    resolve([]);
                }
            );
        })
    }

    loadBrands(searchTerm: String) {
        let vm = this;

        return new Promise((resolve, reject) => {
            // This code modification helps prevent the hirachy search blockage for brands COM-18838
            let advertiserIdentificat = vm.searchParams.advertiserId ? vm.searchParams.advertiserId : null;
            if (advertiserIdentificat === null) {
                vm.BrandsResourceFactory.getAll(
                    {
                        name: searchTerm,
                        providerAccountId: vm._sessionData.accountId,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (brands: any) => {
                        resolve(brands);
                    },
                    () => {
                        resolve([]);
                    }
                );
            } else {
                vm.BrandsResourceFactory.getAll(
                    {
                        name: searchTerm,
                        advertiserId: advertiserIdentificat,
                        providerAccountId: vm._sessionData.accountId,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (brands: any) => {
                        resolve(brands);
                    },
                    () => {
                        resolve([]);
                    }
                );
            }



        })
    }

    showCreatedDate() {
        let vm = this;

        let position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.created-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        let createdDatePanel = {
            attachTo: angular.element(document.body),
            controller: function () { },
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('./createdDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setCreatedDateSearchText();
            },
        };

        vm.$mdPanel.open(createdDatePanel);
    }

    showUpdatedDate() {
        let vm = this;

        let position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.updated-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        let updatedDatePanel = {
            attachTo: angular.element(document.body),
            controller: UpdatedDatePanelController,
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('./updatedDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setUpdatedDateSearchText();
            },
            locals: {
                minDate: vm.minDate,
            },
        };

        vm.$mdPanel.open(updatedDatePanel);
    }

    showValidDate() {
        let vm = this;

        let position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.valid-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        let validDatePanel = {
            attachTo: angular.element(document.body),
            controller: function () { },
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('./validDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setValidDateSearchText();
            },
        };

        vm.$mdPanel.open(validDatePanel);
    }

    selectedAgency(agency: any) {
        let vm = this;

        if ((agency && agency.id != vm.searchParams.agencyId) || agency == null) {
            vm.searchAdvertiser = '';
            vm.searchParams.advertiserId = null;
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }

        vm.searchParams.agencyId = agency ? agency.id : null;

        if (vm.searchAgencies == '') {
            vm.searchParams.agencyId = null;
            vm.searchAdvertiser = '';
            vm.searchParams.advertiserId = null;
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }

        vm.newAgency = true;
    }

    selectedAdvertiser(advertiser: any) {
        let vm = this;

        if (
            (advertiser && advertiser.id != vm.searchParams.advertiserId) ||
            advertiser == null
        ) {
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }

        vm.searchParams.advertiserId = advertiser ? advertiser.id : null;

        if (vm.searchAdvertiser == '') {
            vm.searchParams.advertiserId = null;
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }
        vm.newAdvertiser = true;
    }

    selectedBrand(brand: any) {
        let vm = this;

        vm.searchParams.brandId = brand ? brand.id : null;

        if (vm.searchBrand == '') {
            vm.searchParams.brandId = null;
        }
        vm.newBrand = true;
    }

    disableSearch() {
        let vm = this;

        return (
            (vm.searchParams.notes &&
                vm.searchParams.notes.length > 0 &&
                vm.searchParams.notes.length < 2) ||
            (vm.searchAgencies &&
                vm.searchAgencies.length > 0 &&
                vm.searchAgencies.length < 2) ||
            (vm.searchAdvertiser &&
                vm.searchAdvertiser.length > 0 &&
                vm.searchAdvertiser.length < 2) ||
            (vm.searchBrand && vm.searchBrand.length > 0 && vm.searchBrand.length < 2)
        );
    }

    preventEnter(event: any, type: String) {
        let vm = this;

        if (
            event.keyCode === 13 &&
            ((vm.newAgency === false && type === 'agency') ||
                (vm.newAdvertiser === false && type === 'advertiser') ||
                (vm.newBrand === false && type === 'brand'))
        ) {
            event.preventDefault();
        }
        if (event.keyCode !== 13 && type === 'agency') {
            vm.newAgency = false;
        }
        if (event.keyCode !== 13 && type === 'advertiser') {
            vm.newAdvertiser = false;
        }
        if (event.keyCode !== 13 && type === 'brand') {
            vm.newBrand = false;
        }
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _setCreatedDateSearchText() {
        let vm = this;

        let returnText = '';
        if (!vm.searchParams.createdDateFrom && !!vm.searchParams.createdDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.createdDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateCreated =
                'lte:' +
                moment(new Date(vm.searchParams.createdDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (
            !!vm.searchParams.createdDateFrom &&
            !vm.searchParams.createdDateTo
        ) {
            returnText =
                'after ' + moment(vm.searchParams.createdDateFrom).format('MM/DD/YYYY');
            vm.searchParams.dateCreated =
                'gte:' +
                moment(new Date(vm.searchParams.createdDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (
            !!vm.searchParams.createdDateFrom &&
            !!vm.searchParams.createdDateTo
        ) {
            returnText =
                moment(vm.searchParams.createdDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.createdDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateCreated =
                'bt:[' +
                moment(new Date(vm.searchParams.createdDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.createdDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.createdDateSearchText = returnText;
    }

    _setUpdatedDateSearchText() {
        let vm = this;

        let returnText = '';
        if (!vm.searchParams.updatedDateFrom && !!vm.searchParams.updatedDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'lte:' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (
            !!vm.searchParams.updatedDateFrom &&
            !vm.searchParams.updatedDateTo
        ) {
            returnText =
                'after ' + moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'gte:' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (
            !!vm.searchParams.updatedDateFrom &&
            !!vm.searchParams.updatedDateTo
        ) {
            returnText =
                moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'bt:[' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.updatedDateSearchText = returnText;
    }

    _setValidDateSearchText() {
        let vm = this;

        let returnText = '';
        if (!vm.searchParams.validFrom && !!vm.searchParams.validTo) {
            returnText =
                'before ' + moment(vm.searchParams.validTo).format('MM/DD/YYYY');
            vm.searchParams.runStartDate =
                'lte:' +
                moment(new Date(vm.searchParams.validTo))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            vm.searchParams.runEndDate =
                'lte:' +
                moment(new Date(vm.searchParams.validTo))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (!!vm.searchParams.validFrom && !vm.searchParams.validTo) {
            returnText =
                'after ' + moment(vm.searchParams.validFrom).format('MM/DD/YYYY');
            vm.searchParams.runEndDate =
                'gte:' +
                moment(new Date(vm.searchParams.validFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            vm.searchParams.runStartDate =
                'gte:' +
                moment(new Date(vm.searchParams.validFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (!!vm.searchParams.validFrom && !!vm.searchParams.validTo) {
            returnText =
                moment(vm.searchParams.validFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.validTo).format('MM/DD/YYYY');
            vm.searchParams.runStartDate =
                'lte:' +
                moment(new Date(vm.searchParams.validTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
            vm.searchParams.runEndDate =
                'gte:' +
                moment(new Date(vm.searchParams.validFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
        }
        vm.validDateSearchText = returnText;
    }

    convertDuration() {
        let vm = this;

        let newDuration;
        let totalSeconds = 0;
        newDuration = vm.searchParams.duration
            ? vm.searchParams.duration.split(':')
            : [];
        if (newDuration.length > 1) {
            for (let i = 0; i < newDuration.length - 1; i++) {
                newDuration[i] *= 60;
            }
            for (let j = 0; j < newDuration.length; j++) {
                totalSeconds += parseInt(newDuration[j], 10);
            }
            vm.searchParams.duration = totalSeconds;
        } else {
            vm.searchParams.duration = newDuration[0];
        }
    }

    _setAgencySearchText(agencyId: Number) {
        let vm = this;

        vm.AgencyResourceFactory.get({ id: agencyId }).subscribe(
            function (agency: any) {
                vm.searchAgencies = agency.name;
            }
        );
    }

    _setAdvertiserSearchText(advertiserId: Number) {
        let vm = this;

        vm.AdvertiserResourceFactory.get({ id: advertiserId }).subscribe(
            (advertiser: any) => {
                vm.searchAdvertiser = advertiser.name;
            }
        );
    }

    _setBrandSearchText(brandId: Number) {
        let vm = this;

        vm.BrandsResourceFactory.get({ id: brandId }).subscribe(
            (brand: any) => {
                vm.searchBrand = brand.name;
            }
        );
    }
}
