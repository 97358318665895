/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.spots').controller('spotStatisticsDialogController', ['$filter', '$state', '$mdDialog', 'spot', 'SpotResourceFactory', function ($filter, $state, $mdDialog, spot, SpotResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.spot = spot;
    vm.uploadDuration = '';
    vm.uploadEta = '';
    vm.uploadStartDate = null;
    vm.uploadSpeed = null;
    /* BINDABLE : METHODS*/

    vm.close = close;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function close() {
      $mdDialog.cancel();
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if (vm.spot.uploadStartDate) {
        SpotResourceFactory.getAssetStats({
          id: vm.spot.id
        }).subscribe(function (assetStats) {
          vm.uploadStartDate = moment(vm.spot.uploadStartDate).tz(moment.tz.guess()).format('MM/DD/YY h:mm:ss A z'); //Sanitize, just because it's best

          assetStats.fileSize = parseInt(assetStats.fileSize, 10);
          var start = moment(vm.spot.uploadStartDate); // Allow the end date to be NOW, so we get a rolling duration

          var end = moment(vm.spot.uploadEndDate) || moment();
          var diff = moment.utc(moment.duration(end.diff(start)));
          vm.uploadDuration = diff ? diff.format('H:mm:ss:SSS') : '';

          if (assetStats.fileSize && (vm.spot.uploadProgressPct || vm.spot.uploadEndDate)) {
            var amountToCount = assetStats.fileSize / (vm.spot.uploadProgressPct / 100 || 1);
            vm.uploadSpeed = $filter('number')(amountToCount / diff.seconds() / 1000000, 2) + ' MBps';
          }
        });
      }

      if (vm.spot.uploadETA) {
        vm.uploadEta = vm.spot.uploadETA ? moment.duration(vm.spot.uploadETA).format('H:mm:ss') : '';
      }
    }
  }]);
})();